.md-switching-timeline-view-cont .mbsc-segmented {
    max-width: 350px;
    margin: 0 auto;
    padding: 1px;
}

.mbsc-calendar-controls {
    justify-content: space-between;
}

.md-work-week-nav {
    width: 200px;
}

.md-switching-timeline-view-cont .mbsc-timeline-resource {
    display: flex;
    align-items: center;
}

.md-switching-timeline-view-cont .mbsc-timeline-resource-col {
    width: 205px;
}

@supports (overflow:clip) {
    .md-switching-timeline-view-cont.mbsc-ltr .mbsc-schedule-event-inner {
        left: 205px;
    }

    .md-switching-timeline-view-cont.mbsc-rtl .mbsc-schedule-event-inner {
        right: 205px;
    }
}

/* material header order */

.mbsc-material.md-work-week-prev {
    order: 2;
}

.mbsc-material.md-work-week-next {
    order: 4;
}

.mbsc-material.md-work-week-nav {
    order: 5;
}

.mbsc-material .md-work-week-picker {
    order: 1;
}

.mbsc-material .md-work-week-today {
    order: 3;
}

/* windows header order */

.mbsc-windows.md-work-week-nav {
    order: 2;
}

.mbsc-windows.md-work-week-prev {
    order: 4;
}

.mbsc-windows.md-work-week-next {
    order: 5;
}

.mbsc-windows .md-work-week-picker {
    order: 1;
}

.mbsc-windows .md-work-week-today {
    order: 3;
}